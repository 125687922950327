import { Container, Stack, Box } from '@mui/material';
// components
import Page from '../../components/Page';

export default function TermsOfService() {
  return (
    <Page title="TermsOfService">
      <Container
        maxWidth="lg"
        mt="10%"
        mb="10%"
        sx={{ marginTop: '10%', paddingBottom: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Stack spacing={2} display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <h1>Terms of Service</h1>
          </Box>
          <Stack spacing={2} display="flex" justifyContent="center" alignItems="left">
            <p>
              Welcome to our website. This site is maintained as a service to our customers. By using this site, you
              agree to comply with and be bound by the following terms and conditions of use. Please review these terms
              and conditions carefully. If you do not agree to these terms and conditions, you should not use this site.
            </p>
            <p>
              1. <b>Agreement.</b> This Term of Use agreement ("the "Agreement") specifies the Terms and Conditions for
              access to and use of ismycustomermoving.com (the "Site") and describe the terms and conditions applicable
              to your access of and use of the Site. This Agreement may be modified at any time by IsMyCustomerMoving
              LLC, a Tennessee limited liability company, the operator of the Site upon posting of the modified
              Agreement. Any such modifications shall be effective immediately. You can view the most recent version of
              these terms at any time at ismycustomermoving.com. Each use by you shall constitute and be deemed your
              unconditional acceptance of this Agreement.
            </p>
            <p>
              2. <b>Privacy.</b> Your visit to our site is also governed by our Privacy Policy. Please review our
              Privacy Policy at https://www.ismycustomermoving.com/tos.
            </p>
            <p>
              3. <b>Ownership.</b> All content included on this site is and shall continue to be the property of
              IsMyCustomerMoving LLC or its content suppliers and is protected under applicable copyright, patent,
              trademark, and other proprietary rights. Any copying, redistribution, use or publication by you of any
              such content or any part of the Site is prohibited, except as expressly permitted in this Agreement. Under
              no circumstances will you acquire any ownership rights or other interest in any content by or through your
              use of this Site.
            </p>
            <p>
              4. <b>Intended Audience.</b> This website is intended for adults only. This website is not intended for
              any children under the age of 13.
            </p>
            <p>
              5. <b>Patents and Trademarks.</b> IsMyCustomerMoving LLC claims and asserts certain intellectual property
              rights with respect to the content and functionality made available at ismycustomermoving.com (the
              “Intellectual Property”). Other product and company names mentioned on this Site may be trademarks of
              their respective owners.
            </p>
            <p>
              6. <b>Site Use.</b> IsMyCustomerMoving LLC grants you a limited, revocable, nonexclusive license to use
              this site solely for your own personal use and not for republication, distribution, assignment,
              sublicense, sale, preparation of derivative works, or other use. You agree not to copy materials on the
              site, reverse engineer or break into the site, or use materials, products or services in violation of any
              law, or otherwise interfere with or appropriate any Intellectual Property. The use of this website is at
              the discretion of IsMyCustomerMoving LLC, and IsMyCustomerMoving LLC may terminate your use of this
              website at any time.
            </p>
            <p>
              7. <b>Compliance with Laws.</b> You agree to comply with all applicable laws regarding your use of the
              website and all content and functionality available at ismycustomermoving.com. You further agreed that
              information provided by you is truthful and accurate to the best of your knowledge.
            </p>
            <p>
              8. <b>Indemnification.</b> You agree to indemnify, defend and hold IsMyCustomerMoving LLC and our
              partners, employees, and affiliates, harmless from any liability, loss, claim and expense, including
              reasonable attorney's fees, related to your violation of this Agreement or use of the Site.
            </p>
            <p>
              9. <b>Disclaimer.</b> THE INFORMATION ON THIS SITE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS. YOU
              AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK. IsMyCustomerMoving LLC DISCLAIMS ALL WARRANTIES OF ANY
              KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY IMPLIED W
              ARRANTIES OF MERCHANT ABILITY , FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE EXTENT YOUR
              JURISDICTION DOES NOT ALLOW LIMIT A TIONS ON W ARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE
              AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SITE SHALL BE TO DISCONTINUE USING THE SITE.
            </p>
            <p>
              10. <b>Limitation of Liability.</b> UNDER NO CIRCUMSTANCES WILL IsMyCustomerMoving LLC BE LIABLE OR
              RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS,
              LOST PROFITS, LITIGA TION, OR THE LIKE), SPECIAL, EXEMPLARY , PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL
              THEORY , ARISING OUT OF OR IN ANY W A Y RELATING TO THE SITE, YOUR SITE USE, OR THE CONTENT, EVEN IF
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE AND/OR
              CONTENT IS TO CEASE ALL OF YOUR SITE USE.
            </p>
            <p>
              You may have additional rights under certain laws (including consumer laws) which do not allow the
              exclusion of implied warranties, or the exclusion or limitation of certain damages. If these laws apply to
              you, the exclusions or limitations in this Agreement that directly conflict with such laws may not apply
              to you.
            </p>
            <p>
              11. <b>Use of Information.</b> IsMyCustomerMoving LLC reserves the right, and you authorize us, to use and
              assign all information regarding site uses by you and all information provided by you in any manner
              consistent with our Privacy Policy.
            </p>
            <p>
              12. <b>Copyrights and Copyright Agent.</b> If you believe your work has been copied in a way that
              constitutes copyright infringement, or your intellectual property rights have otherwise been violated,
              please provide a notice containing all of the following information to our Copyright Agent:
            </p>
            <p>
              &emsp;(a) An electronic or physical signature of the person authorized to act on behalf of the owner of
              the copyright or other intellectual property interest;
            </p>
            <p>&emsp;(b) A description of the copyrighted work that you claim has been infringed;</p>
            <p>&emsp;(c) A description of where the material that you claim is infringing is located on the Site;</p>
            <p>&emsp;(d) Your address, telephone number, and e-mail address;</p>
            <p>
              &emsp;(e) A statement by you that you have a good faith belief that the disputed use is not authorized by
              the copyright owner, its agent, or the law
            </p>
            <p>
              &emsp;(f) A statement by you, made under penalty of perjury, that the above information in your notice is
              accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
            </p>
            <p>
              &emsp;Our Copyright Agent for Notice of claims of copyright infringement on the Site is Matthew A.
              Grossman, who can be reached as follows:
            </p>
            <p>&emsp;By Mail: 550 W. Main St., 500 Knoxville, TN 37902</p>
            <p>&emsp;By Phone: 865-546-9321</p>
            <p>&emsp;By E-mail: mgrossman@fmsllp.com</p>
            <p>
              13. <b>Applicable Law and Forum Selection.</b> You agree that the laws of the state of Tennessee, without
              regard to conflicts of laws provisions will govern these Terms and Condition of Use and any dispute that
              may arise between you and IsMyCustomerMoving LLC or its affiliates. Any litigation which may arise between
              you and IsMyCustomerMoving LLC shall be adjudicated solely in the Chancery Court for Knox County,
              Tennessee (the “Forum Court”), to the exclusion of any other court which may otherwise be of competent
              jurisdiction, and You expressly waive any right which you may have to initiate any action in any other
              forum, and You expressly waive any right You may have to remove any action from the Forum Court to any
              court of the United States. By accepting these Terms of Service and accessing the Site, You expressly
              agree to submit yourself to the jurisdiction of the Forum Court and waive rights as set forth herein, and
              You expressly agree that IsMyCustomerMoving LLC’s provision of access to the Site is sufficient
              consideration for this forum selection and forum waivers.
            </p>
            <p>
              14. <b>Severability.</b> If any provision of this Agreement shall be adjudged by any court of competent
              jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum
              extent necessary so that this Agreement will otherwise remain in full force and effect.
            </p>
            <p>
              15. <b>Waiver.</b> The failure of IsMyCustomerMoving LLC to exercise or enforce any right or provision of
              this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by
              IsMyCustomerMoving LLC must be in writing and signed by an authorized representative of IsMyCustomerMoving
              LLC.
            </p>
            <p>
              16. <b>Termination.</b> IsMyCustomerMoving LLC may terminate this Agreement at any time, with or without
              notice, for any reason.
            </p>
            <p>
              17. <b>Relationship of the Parties.</b> Nothing contained in this Agreement or your use of the Site shall
              be construed to constitute either party as a partner, joint venturer, employee or agent of the other
              party, nor shall either party hold itself out as such. Neither party has any right or authority to incur,
              assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind,
              express or implied, in the name of or on behalf of the other party, it being intended by both parties that
              each shall remain independent contractors responsible for its own actions.
            </p>
            <p>
              18. <b>Entire Agreement</b> This Terms of Use constitutes the entire agreement between you and
              IsMyCustomerMoving LLC and governs the terms and conditions of your use of the Site, and supersedes all
              prior or contemporaneous communications and proposals, whether electronic, oral or written, between you
              and [name of website] with respect to this Site. Notwithstanding the foregoing, you may also be subject to
              additional terms and conditions, posted policies (including but not limited to the Privacy Policy),
              guidelines, or rules that may apply when you use the website. IsMyCustomerMoving LLC may revise this Terms
              of Use at any time by updating this Agreement and posting it on the Site. Accordingly, you should visit
              the Site and review the Terms of Use periodically to determine if any changes have been made. Your
              continued use of this website after any changes have been made to the Terms of Use signifies and confirms
              your acceptance of any such changes or amendments to the Terms of Use.
            </p>
            <p>
              19. <b>Contact Information.</b>
            </p>
            <p>&emsp;IsMyCustomerMoving LLC</p>
            <p>&emsp;6339 Charlotte Pk., Unit 643 Nashville, TN 37209</p>
            <p>&emsp;615-685-6848</p>
            <p>&emsp;jb@ismycustomermoving.com</p>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
