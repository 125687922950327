import { Container, Stack, Box } from '@mui/material';
// components
import Page from '../../components/Page';

export default function PrivacyPolicy() {
  return (
    <Page title="PrivacyPolicy">
      <Container
        maxWidth="lg"
        mt="10%"
        mb="10%"
        sx={{ marginTop: '10%', paddingBottom: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Stack spacing={2} display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <h1>Privacy Policy</h1>
          </Box>
          <Stack spacing={2} display="flex" justifyContent="center" alignItems="left">
            <p>
              1. <b>Introduction.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Web Site Owner. IsMyCustomerMoving LLC, a Tennessee limited liability company ("IsMyCustomerMoving")
              is the owner of this web site (ismycustomermoving.com "IsMyCustomerMoving Web Site"). IsMyCustomerMoving
              can be contacted by mail at 6339 Charlotte Pk., Unit 643, Nashville, TN 37209, by phone at 615-685-6848,
              or by e-mail at jb@ismycustomermoving.com. This online privacy notice discloses IsMyCustomerMoving’s
              information practices for this IsMyCustomerMoving Web Site, including what type of personal identifiable
              information is requested in order to make a purchase, how the information is used, and with whom the
              in-formation is shared.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Web Site Visits. In general, you can visit on the IsMyCustomerMoving Web Site without disclosing any
              personal information. IsMyCustomerMoving may keep track of the domains from which people visit us and/or
              IsMyCustomerMoving analyzes the data gathered from Web Site visits for trends and statistics, and then
              IsMyCustomerMoving discards the gathered data.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Web Site Transactions. At times, IsMyCustomerMoving will need personal information regarding a
              customer or a prospect. For example to process an order or provide a subscription, IsMyCustomerMoving may
              need to know a customer's name, mailing address, e-mail address and credit card details. It is
              IsMyCustomerMoving's intent to inform you know before IsMyCustomerMoving collects personal information,
              such as user's name and/or address on the Internet.
            </p>
            <p>
              2. <b>Personal Information That May Be Collected.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Identifying Information. In order to make a purchase or to access designated subscriber services and
              /or restricted areas within the IsMyCustomerMoving Web Site, IsMyCustomerMoving will request a user to
              provide certain personal identifying information, which may include: legal name, postal address, e-mail
              address, screen name, password, daytime telephone number, facsimile number, method of payment, and, if
              applicable, credit card number or other banking and/or payment information. IsMyCustomerMoving may request
              additional information necessary to establish and maintain customer's account.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Service Quality Monitoring. Some Web site transactions may require a customer to telephone
              IsMyCustomerMoving, or IsMyCustomerMoving to call the customer. IsMyCustomerMoving will not contact you by
              telephone without your prior consent, except to confirm an order placed online and/or to inform a customer
              of the status of such order. Customer should be aware that it is IsMyCustomerMoving 's practice to
              monitor, and in some cases record such calls for staff training or quality assurance purposes.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Information from Children. IsMyCustomerMoving does not sell products or services for purchase by
              children, and will not collect or post information from a child under the age of 18 without the
              involvement of a parent or guardian. IsMyCustomerMoving will notify the child's parent or guardian at the
              e-mail address provided by the prospective customer, alerting the parent or guardian to the child's use of
              the Web site and providing instructions as to how the parent or guardian can delete the child's
              registration from the Web site. IsMyCustomerMoving does not use personally identifying information
              collected from children for marketing or promotional purposes, and does not disclose such information to
              any third party for any purpose whatsoever.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (d) Lost or Stolen Information. If a customer's credit card, other banking information, and/or password is
              lost or stolen, the customer should promptly notify IsMyCustomerMoving in order to enable
              IsMyCustomerMoving to cancel the lost or stolen information and to update its records with a changed
              credit card, other banking information, and/or password.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (e) Chat Rooms, Forums and Bulletin Boards. If customer participates in an IsMyCustomerMoving chat room,
              discussion forum, or posts messages to an IsMyCustomerMoving bulletin board, customer should be aware that
              the information disclosed and shared will be broadly available to other persons, both inside of and/or
              outside IsMyCustomerMoving, who have access to that chat room, forum or bulletin board. Some individual
              IsMyCustomerMoving chat rooms, forums or bulletin boards have additional rules and conditions regarding
              participation. Also, participant's expressed opinion is his or her own and should not be considered as
              reflecting the opinion of IsMyCustomerMoving.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (f) Links to Other Web Sites. An IsMyCustomerMoving Web Site may contain links to other web sites.
              IsMyCustomerMoving is not responsible for the privacy practices or the content of those other web sites.
            </p>
            <p>
              3. <b>Uses Made of the Information.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Limited Uses Identified. Without customer's prior consent, IsMyCustomerMoving will not use your
              personal identifiable information for any purpose other than that for which it is submitted.
              IsMyCustomerMoving uses personal identifiable information to reply to inquiries, handle complaints,
              provide operational notices and in program record-keeping. IsMyCustomerMoving also processes billing and
              business requests related to IsMyCustomerMoving Web Site participation.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Marketing Uses. IsMyCustomerMoving reserves the right to provide customer with information about
              IsMyCustomerMoving's Web site, IsMyCustomerMoving products and services, and related information in which
              customer has indicated an interest.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Stored Information Uses. IsMyCustomerMoving stores and retains the information provided by customer
              and the information entered on the IsMyCustomerMoving Web site. This information is used to compile a
              customer's purchase history in order to enable IsMyCustomerMoving to recommend products, services, or
              special offers that would be of interest to a customer.
            </p>
            <p>
              4. <b>Disclosure of the Information.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Within Corporate Organization. IsMyCustomerMoving is a manager-managed Tennessee limited liability
              company. Information IsMyCustomerMoving obtains may be shared among the members, managers, and/or
              employees of IsMyCustomerMoving for the purpose of carrying on the business of IsMyCustomerMoving and for
              the purpose of recommending products, services, or special offers that would be of interest to a customer.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Mergers and Acquisitions. Circumstances may arise where, for business reasons, IsMyCustomerMoving may
              sell, buy, merge or otherwise reorganize its businesses in the United States or some other country. Such a
              transaction may involve the disclosure of personal identifying information to prospective or actual
              purchasers, and/or receiving such information from sellers.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Agents. IsMyCustomerMoving may employ or engage other companies and/or individuals to perform business
              functions on behalf of IsMyCustomerMoving. These persons are provided with personal identifying
              information required to perform their functions, but are prohibited by contract from using the information
              for other purposes. These persons engage in a variety of functions which include, but are not limited to,
              fulfilling orders, delivering packages, removing repetitive information from customer lists, analyzing
              data, providing marketing assistance, processing credit card payments and providing customer services.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (d) Marketing Analysis by Third Parties. recommend products, services, or special offers that would be of
              interest to a customer reserves the right to disclose to third parties personal information about
              customers for marketing analysis.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (e) Disclosure to Governmental Authorities. Under certain circumstances, personal information may be
              subject to disclosure pursuant to a judicial or other government subpoenas, warrants or orders.
            </p>
            <p>
              5. <b>Use of Computer Tracking Technologies.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) No Tracking of Personal Information. The IsMyCustomerMoving Web Site is not set up to track, collect
              or distribute personal information not entered by visitors. Through web site access logs
              IsMyCustomerMoving does collect clickstream data and HTTP protocol elements, which generate certain kinds
              of non-identifying site usage data, such as the number of hits and visits to our sites. This information
              is used for internal purposes by technical support staff for research and development, user analysis and
              business decision making, all of which provides better services to the public. The statistics garnered,
              which contain no personal information and cannot be used to gather such information, may also be provided
              to third parties.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Use of Cookies. IsMyCustomerMoving, or its third party vendors, collects non-identifiable and personal
              information through the use of various technologies, including "cookies". A cookie is an alphanumeric
              identifier that a web site can transfer to customer's hard drive through customer's browser. The cookie is
              then stored on customer's computer as an anonymous tag that identifies the customer's computer, but not
              the customer. Cookies may be sent by IsMyCustomerMoving or its third party vendors. Customer can set its
              browser to notify customer before a cookie is received, giving an opportunity to decide whether to accept
              the cookie. Customer may also set its browser to turn off cookies; however, some web sites may not then
              work properly.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Use of Web Beacon Technologies. IsMyCustomerMoving may also use web beacon or other technologies to
              better tailor its Web site(s) to provide better customer service. If these technologies are in use, when a
              visitor accesses these pages of the Web site, a non-identifiable notice of that visit is generated which
              may be processed by IsMyCustomerMoving or by its suppliers. Web beacons usually work in conjunction with
              cookies. If customer does not want cookie information to be associated with customer's visits to these
              pages, customer can set its browser to turn off cookies; however, Web beacon and other technologies will
              still detect visits to these pages, but the notices they generate cannot be associated with other
              non-identifiable cookie information and are disregarded.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (d) Collection of Non-Identifiable Information. IsMyCustomerMoving may collect non- identifiable
              information from user visits to the IsMyCustomerMoving Web Site in order to provide better customer
              service. Examples of such collecting include: traffic analysis, such as tracking of the domains from which
              users visit, or tracking numbers of visitors; measuring visitor activity on the IsMyCustomerMoving Web
              Site; Web site and system administration; user analysis; and business decision making. Such information is
              sometimes known as "clickstream data." IsMyCustomerMoving or its contractors may use this data to analyze
              trends and statistics.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (e) Collection of Personal Information. IsMyCustomerMoving collects personal identifying information from
              customer during a transaction. IsMyCustomerMoving may extract some personally identifying information
              about that transaction in a non-identifiable format and combine it with other non-identifiable
              information, such as clickstream data. This information is used and analyzed only at an aggregate level
              (not at an individual level) to help IsMyCustomerMoving understand trends and patterns. This information
              is not reviewed at an individual level.
            </p>
            <p>
              6. <b>Information Security.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Commitment to Online Security. IsMyCustomerMoving employs physical, electronic and managerial
              procedures to safeguard the security and integrity of personal information. Billing and payment data is
              encrypted whenever transmitted or received online. Personal information is accessible only by staff
              designated to handle online transactions, requests, and/or complaints.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) No Liability for Acts of Third Parties. IsMyCustomerMoving will exercise all reasonable efforts to
              safeguard the confidentiality of customer personal information. However, transmissions protected by
              industry standard security technology and implemented by human beings cannot be made absolutely secure.
              Consequently, IsMyCustomerMoving shall not be liable for unauthorized disclosure of personal information
              due to no fault of IsMyCustomerMoving including, but not limited to, errors in transmission and
              unauthorized acts of IsMyCustomerMoving staff and/or third parties.
            </p>
            <p>
              7. <b>Privacy Policy Changes and Opt-Out Rights.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Changes to Privacy Policy. This privacy notice was last updated on October 4, 2022. IsMyCustomerMoving
              reserves the right to update its privacy policy statement at any time. A notice of any material change
              will be posted on the IsMyCustomerMoving Web Site.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Opt-Out Right. Customer and/or prospective customer has the right at any time to cease permitting
              personal information to be collected, used or disclosed by IsMyCustomerMoving and/or by any third parties
              with whom IsMyCustomerMoving has shared and/or transferred such personal information. Right of
              cancellation may be exercised by contacting IsMyCustomerMoving via e-mail or certified postal mail. After
              processing the cancellation, IsMyCustomerMoving will delete customer or prospective customer's personal
              information from its data base.
            </p>
            <p>
              8. <b>Access Rights to Data.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Information Maintained by IsMyCustomerMoving. Upon customer's request, IsMyCustomerMoving will provide
              a reasonable description of customer's personally identifiable information that IsMyCustomerMoving
              maintains in its data bank. IsMyCustomerMoving can be contacted by e-mail or certified postal mail.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Corrections and Changes to Personal Information. Help IsMyCustomerMoving to keep customer personal
              information accurate. If customer's personal information changes, or if customer notes an error upon
              review of customer information that IsMyCustomerMoving has on file, please promptly e-mail
              IsMyCustomerMoving and provide the new or correct information.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (c) Your California Privacy Rights. Beginning on January 1, 2005, California Civil Code Section 1798.83
              permits customers of IsMyCustomerMoving who are California residents to request certain information
              regarding IsMyCustomerMoving’s disclosure of personal information for their direct marketing purposes. To
              make such a request, please write to: [name and [address or e-mail address]]. Within 30 days of receiving
              such a request, IsMyCustomerMoving will provide a list of the categories of personal information disclosed
              to third parties for third-party direct marketing purposes during the immediately preceding calendar year,
              along with the names and addresses of these third parties. This request may be made no more than once per
              calendar year. IsMyCustomerMoving reserves its right not to respond to requests submitted other than to
              the address specified in this paragraph.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              California’s privacy laws require a company to provide notice to California users of their rights to
              receive information on to which entities their information was shared for marketing purposes.
            </p>
            <p>
              9. <b>Accountability.</b>
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (a) Questions, Problems and Complaints. If you have a question about this policy statement, or a complaint
              about IsMyCustomerMoving compliance with this privacy policy, you may contact IsMyCustomerMoving by
              e-mail. If IsMyCustomerMoving is unable to resolve your complaint to your reasonable satisfaction or if
              customer does not receive acknowledgment of an inquiry, customer may elect to proceed by contacting
              provide name of third party privacy service organization and information on how to contact the
              organization.
            </p>
            <p style={{ paddingLeft: '5%' }}>
              (b) Terms of Use. If customer chooses to enter into a purchase order or to subscribe to
              IsMyCustomerMoving's services, customer's action is hereby deemed acceptance of IsMyCustomerMoving
              practices described in this policy statement. Any dispute over privacy between customer and
              IsMyCustomerMoving is subject to the provisions of this notice and to IsMyCustomerMoving 's Terms of
              Service which is hereby incorporated herein and which can be read at IsMyCustomerMoving’s Web Site.
            </p>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
